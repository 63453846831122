import React, { useRef, useCallback, useEffect, useMemo } from "react";
import { useDropArea as useBaseDropArea } from "react-use";
import { parseDate } from "@internationalized/date";
import Papa, { ParseResult } from "papaparse";
import * as yup from "yup";

import { Paperclip } from "@puzzle/icons";
import { formatNumber, formatMoney } from "@puzzle/utils";
import {
  Text,
  useToasts,
  DataTable,
  useDataTable,
  createColumnHelper,
  Alert,
  Button,
  ScrollArea,
} from "@puzzle/ui";
import { styled } from "@puzzle/theme";

import { DropZone, Attachment } from "components/common/files/styles";
import { useFile } from "components/common/files/useFile";
import Analytics from "lib/analytics/analytics";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { AssociatedEntity } from "graphql/types";
import { reportError } from "lib/errors/errors";
import { schemaValidators } from "components/common/import/utils";

import { Link } from "./ImportTransactionsModal";
import { ParsedCSV, useImportState } from "./useImportState";
import { InvalidImportMessage } from "./InvalidImportMessage";

const TableContainer = styled("div", {
  display: "flex",
  maxHeight: "440px",
  paddingTop: "$1",

  table: {
    height: "100%",

    th: {
      background: "#1D1D26 !important",
      border: "none !important",
    },

    tr: {
      background: "#242430",
    },
    tbody: {
      "tr:not(.subrow):not(.subnode):not(.subnode td tr):hover td": {
        backgroundColor: "#242430",
      },
    },
  },
});

const MAX_RECORDS_TO_SHOW = 150;

const recordSchema = yup.object({
  date: schemaValidators.date.required(),
  description: yup.string().required(),
  amount: schemaValidators.amount.required(),
});

// TODO: This needs tests
const parseCSV = (parseResult: ParseResult<string[]>, selectedFile: File | null): ParsedCSV => {
  const invalidRows: Record<string, unknown>[] = [];
  const parsed: ParsedCSV = {
    data: [],
    meta: {
      minDate: null,
      maxDate: null,
      isValid: true,
    },
  };

  // Remove first record since those will be the header values
  parsed.data = parseResult.data.slice(1).flatMap((row) => {
    // Filter out completely empty rows
    const isEmptyRow = row.every((cell) => cell === "");
    if (isEmptyRow) return [];

    // Hardcoded header order for now, can dynamically pull
    // them in with papaparse but can lead to more user error
    const [date, amount, description] = row;

    const record = recordSchema.cast({ date, amount, description });

    const isValidSchema = recordSchema.isValidSync(record);
    if (!isValidSchema) {
      invalidRows.push(record);
      parsed.meta.isValid = false;
    } else {
      const calendarDate = parseDate(record.date);
      const {
        meta: { minDate, maxDate },
      } = parsed;

      if (minDate === null || minDate.compare(calendarDate) > 0) {
        parsed.meta.minDate = calendarDate;
      }

      if (maxDate === null || maxDate.compare(calendarDate) < 0) {
        parsed.meta.maxDate = calendarDate;
      }
    }

    return record;
  });

  if (parsed.data.length === 0) {
    parsed.meta.isValid = false;
  }

  if (!parsed.meta.isValid) {
    Analytics.transactionImportInvalidFormat({
      fileName: selectedFile?.name ?? "",
      fileSize: selectedFile?.size ?? 0,
      contentType: selectedFile?.type ?? "",
    });

    reportError(`TransactionImportInvalidFormat`, { invalidRows, fileName: selectedFile?.name });
  }

  return parsed;
};

export const CSVImport = ({ closeModal }: { closeModal: () => void }) => {
  const { selectedFile, selectedAccountId, updateImportState, parsedCSV } = useImportState();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { company } = useActiveCompany<true>();
  const openFilePicker = useCallback(() => hiddenFileInput.current?.click(), []);
  const [dropAreaProps, { over: isDropping }] = useBaseDropArea({
    onFiles: (files) => {
      if (files[0].type === "text/csv") {
        updateImportState({
          selectedFile: files[0],
        });
      }
    },
  });
  const dropProps = selectedFile ? {} : dropAreaProps;
  const { toast } = useToasts();

  const { onFiles, deleteFile } = useFile({
    maxUploads: 1,
    entityId: company.id,
    entityType: AssociatedEntity.DirectIngest,
    onError: () => {
      closeModal();
      toast({
        title: `Importing transactions failed`,
        message: `Something went wrong. We recommend importing a maximum of 150 transactions at a time; we apologize for the inconvenience. If the problem persists, contact support.`,
        status: "error",
        duration: 10000,
      });
      Analytics.transactionImportCsvUploadFailed({
        fileName: selectedFile?.name ?? "",
        fileSize: selectedFile?.size ?? 0,
        contentType: selectedFile?.type ?? "",
        totalRows: parsedCSV?.data.length ?? 0,
      });
    },
    onUploadComplete: (files) => {
      const [file] = files;
      updateImportState({ uploadedFilePath: file.path });

      // You shouldn't get to this point!
      if (!parsedCSV?.meta.isValid) {
        return;
      }
    },
  });

  useEffect(() => {
    if (selectedFile) {
      onFiles([selectedFile]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    if (!parsedCSV && selectedFile) {
      Papa.parse(selectedFile, {
        // error : () => {} todo: handle error
        complete: (results: ParseResult<string[]>) => {
          updateImportState({
            parsedCSV: parseCSV(results, selectedFile),
          });
        },
      });
    }
  }, [parsedCSV, selectedFile, updateImportState]);

  const tableData = useMemo(() => {
    if (!parsedCSV) return [];

    return parsedCSV.data.slice(0, MAX_RECORDS_TO_SHOW).map((t, i) => {
      return {
        index: i + 1,
        ...t,
      };
    });
  }, [parsedCSV]);

  const shortDateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  const columnHelper = createColumnHelper<{
    index: number;
    date?: string;
    amount?: string;
    description?: string;
  }>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("index", {
        id: "index",
        header: "",
        size: 35,
        meta: {
          align: "center",
        },
      }),
      columnHelper.accessor("date", {
        id: "date",
        header: "Date",
        size: 65,
        cell: ({ getValue }) => {
          const date = getValue();
          const isValidDate = recordSchema.fields.date.isValidSync(date);
          return (
            <Text>{isValidDate ? shortDateFormatter.format(parseDate(date)) : "Invalid date"}</Text>
          );
        },
      }),
      columnHelper.accessor("amount", {
        id: "amount",
        header: "Amount",
        size: 120,
        meta: {
          align: "right",
        },
        cell: ({ getValue }) => {
          const amount = getValue();
          const isValidAmount = recordSchema.fields.amount.isValidSync(amount);
          return (
            <Text>
              {isValidAmount
                ? formatMoney({ currency: "USD", amount }, { truncateValue: false })
                : amount}
            </Text>
          );
        },
      }),
      columnHelper.accessor("description", {
        id: "description",
        header: "Description",
        size: 500,
        cell: ({ getValue }) => {
          return <Text color="$neutral400">{getValue()}</Text>;
        },
      }),
    ];
  }, [columnHelper, shortDateFormatter]);

  const table = useDataTable({
    data: tableData,
    columns,
  });

  return (
    <>
      {!selectedFile ? (
        <DropZone
          isDropping={isDropping && !selectedFile}
          {...dropProps}
          hasSelectedFile={!!selectedFile}
          onClick={openFilePicker}
        >
          <Attachment>
            <Paperclip />
            <Text>Drag and drop or select a .csv file</Text>
            <Button variant="secondary" size="compact" onClick={openFilePicker}>
              Browse my computer
            </Button>
            <input
              type="file"
              accept=".csv"
              ref={hiddenFileInput}
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files) {
                  updateImportState({
                    selectedFile: e.target.files[0],
                  });
                }
              }}
            />
          </Attachment>
        </DropZone>
      ) : (
        <>
          {selectedFile && (
            <Text css={{ color: "$neutral300", fontWeight: "$heavy" }}>
              {selectedFile.name}{" "}
              <Link
                css={{ cursor: "pointer" }}
                onClick={() => {
                  updateImportState({
                    selectedFile: null,
                    parsedCSV: null,
                  });
                  deleteFile();
                }}
              >
                Remove
              </Link>
            </Text>
          )}
          {parsedCSV && !parsedCSV.meta.isValid && <InvalidImportMessage />}
          <TableContainer>
            <ScrollArea css={{ border: "1px solid $neutral800", borderRadius: "4px" }}>
              <ScrollArea.Viewport>
                <DataTable table={table} bordered />
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar orientation="vertical">
                <ScrollArea.Thumb variant="shadowed" />
              </ScrollArea.Scrollbar>
            </ScrollArea>
          </TableContainer>
          {(parsedCSV?.data?.length ?? 0) > MAX_RECORDS_TO_SHOW && (
            <>
              <Alert kind="warning">
                We recommend importing a maximum of {MAX_RECORDS_TO_SHOW} transactions from one
                account at a time. More transactions may cause the import process to time out.
              </Alert>
              <Text
                color="neutral500"
                css={{ display: "flex", marginBottom: "$0h" }}
              >{`Showing ${MAX_RECORDS_TO_SHOW} of ${formatNumber(parsedCSV?.data?.length ?? 0, {
                decimals: 0,
              })} transactions`}</Text>
            </>
          )}
        </>
      )}
    </>
  );
};
